import React, { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import AlertBox from './components/AlertBox';
import cardBgImage from './assets/Summit_Renewal_Background.jpg';
import platBgImage from './assets/PPT_background.jpg';
// @ts-ignore
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import axios from 'axios';
import platinumStacked from './assets/Platinum_Stacked_reversed.png';
import a360reversed from './assets/a360_reversed.png';

export interface Commit {
    save: string
    year: string
    desc?: React.ReactNode;
    discountedFee?: React.ReactNode;
    fee?: React.ReactNode;
    membershipFee?: React.ReactElement
    actionBtns?: ActionBtn[]
}

export interface ActionBtn {
    text: string
    submitting?: boolean
    onClick: () => void
}

export interface LongevityPlatinumProps {
    paymentError?: string
    title: string;
    renewalOptions: { priceAfterwards: string, oneYearFee: string, sixYearFee: string };
    commitTo: Commit;
    commitThru: Commit;
}
{/**
The buttons will then link to a separate deposit form for each offer.
General Structure:
Offer Name
Brief Summary
Form:
Name , Email, Phone
Payment Method: CC or Wire
If CC, show Billing Details: CC#, Exp, CVV, Zip
If Wire, show Wire Info and deadline for Wire to post of EOD on 9/20.
Payment Amount: Pay in Full or Deposit only.

Agree to Terms Checkbox.
A360 Terms:
$23,500 - $2500 = $21K for Annual Global In Person Membership
$10K Deposit Today is 100% refundable for 30 days
Balance of $11K Due By December 31st 2024
I’ll get you the titles/blurbs shortly but if you can just setup the pages with loremipsum for now, it’ll be easier when I can visually see them,.
Plat Terms:
$15K Deposit - $5000 Credit = Only $10K Due Today.
Deposit is 100% refundable until Dec 31st 2024
Balance of $55K Due By March 31st 2025
 */}
const LongevityPlatinum: React.FC = () => {
    const paymentError = null;
    const [showWireInstructions, setShowWireInstructions] = useState(false);
    const [wireType, setWireType] = useState<'a360' | 'plat'>('a360');

    if (paymentError) {
        return (
            <AlertBox
                type='success'
                title={paymentError}
                message={''}
                actionBtns={{
                    postitiveBtnText: 'Login to AbundanceHub',
                    positiveBtnClick(e) {
                        e.preventDefault();
                        window.open('https://hub.a360.com/', '_blank');
                    },
                }}
            />
        );
    }

    return (
        <div className="mx-auto max-w-5xl px-4 py-8 md:px-6 md:py-12 lg:px-8 text-left rounded-lg bg-platBlack mb-4">
            <div>
                <h1 className="text-2xl md:text-3xl font-bold mb-2 uppercase text-center text-gold">Platinum Longevity Attendee Discounts</h1>
            </div>
            <div className="mb-6 mt-8 bg-gray-200 px-4 md:px-10 py-4 md:py-10 rounded-lg">
                <div className="flex flex-col md:flex-row gap-3 items-center justify-between mb-6">
                    <div className="rounded w-full md:flex-1 bg-cover object-cover bg-center bg-no-repeat">
                        <div className="text-4xl font-bold text-black text-center">Bundle and Save $7500</div>
                        <img src={platinumStacked} alt="Abundance Platinum" className="w-full max-w-[500px] mx-auto" />
                        <div className="text-2xl font-bold text-black text-center">AND</div>
                        <img src={a360reversed} alt="A360" className="w-full max-w-[380px] mx-auto" />
                    </div>
                    <p className="text-black mb-2 text-base md:hidden px-2">
                                - Deposit is 100% refundable until Dec 31st 2024<br/>
                                - Balance of $66K (Individual) or $122k (2 People w/ Shared Room) due by March 1st 2025<br/>
                                - Save additional $1000 and get a Room Upgrade at Platinum when you Pay In Full
                    </p>
                    <div className="w-full md:flex-1 m-auto">
                        <div className="text-black mb-4 md:ml-10 text-xl md:text-2xl font-bold">One Person:</div>
                        <ActionButtons actionBtns={[
                            {text:"Pay In Full - $85K", submitting:false, onClick:()=>{window.open('https://buy.stripe.com/dR69CPetn2WaaiYaFI?prefilled_promo_code=plat1000', '_blank');}},
                            {text:"Pay Deposit - $20K", submitting:false, onClick:()=>{window.open('https://buy.stripe.com/8wM7uH5WR9kyfDi013', '_blank');}}
                            ] as ActionBtn[] ?? []} />

                    <div className="text-black mb-4 md:ml-10 text-xl md:text-2xl font-bold mt-6">Two People Sharing Room:</div>
                        <ActionButtons actionBtns={[
                            {text:"Pay In Full - $161K", submitting:false, onClick:()=>{window.open('https://buy.stripe.com/6oE2an4SN54i76M3de?prefilled_promo_code=plat1000', '_blank');}},
                            {text:"Pay Deposit - $40K", submitting:false, onClick:()=>{window.open('https://buy.stripe.com/00g2anclfaoCbn24hl', '_blank');}}] as ActionBtn[] ?? []} />
                    </div>
                </div>
                <p className="text-black mb-6 text-base hidden md:block">
                     - Deposit is 100% refundable until Dec 31st 2024<br/>
                     - Balance of $66K (Individual) or $122k (2 People w/ Shared Room) due by March 1st 2025<br/>
                     - Save additional $1000 and get a Room Upgrade at Platinum when you Pay In Full
                </p>
            </div>

            <div className="mb-6 mt-8 bg-gray-200 px-4 md:px-10 py-6">
                <div className="flex flex-col md:flex-row gap-3 items-center justify-between mb-6">
                    <div className="rounded w-full md:flex-1 bg-cover object-cover bg-center bg-no-repeat">
                        <div className="text-4xl mt-3 font-bold text-black text-center">Save $5000</div>
                        <img src={platinumStacked} alt="Platinum" className="w-full max-w-[500px] mx-auto" />
                    </div>
                    <p className="text-black mb-2 text-base md:hidden px-2">
                    - Deposit is 100% refundable until Dec 31st 2024<br/>
                        - Balance of $55K (Individual) or $100K (2 People w/ Shared Room) due by March 1st 2025<br/>
                    - Save additional $1000 and get a Room Upgrade at Platinum when you Pay In Full
                 </p>
                    <div className="w-full md:flex-1 m-auto">
                        <div className="text-black mb-4 md:ml-10 text-xl md:text-2xl font-bold">One Person:</div>
                        <ActionButtons actionBtns={[
                            {text:"Pay In Full - $65K", submitting:false, onClick:()=>{window.open('https://buy.stripe.com/3csaGTdpj0O2aiY7tt', '_blank')}},
                            {text:"Pay Deposit - $10K", submitting:false, onClick:()=>{window.open('https://buy.stripe.com/28ocP13OJ68maiY4h9', '_blank')}}] as ActionBtn[] ?? []} />

                    <div className="text-black mb-4 md:ml-10 text-xl md:text-2xl font-bold mt-6">Two People Sharing Room:</div>
                        <ActionButtons actionBtns={[
                            {text:"Pay In Full - $120K", submitting:false, onClick:()=>{window.open('https://buy.stripe.com/28oaGT1GBcwK1MsdRQ', '_blank')}},
                            {text:"Pay Deposit - $20K", submitting:false, onClick:()=>{window.open('https://buy.stripe.com/cN26qDetn9ky8aQ153', '_blank')}}] as ActionBtn[] ?? []} />

                    </div>
                </div>
                <p className="text-black mb-6 text-base hidden md:block">
                - Deposit is 100% refundable until Dec 31st 2024<br/>
                        - Balance of $55K (Individual) or $100K (2 People w/ Shared Room) due by March 1st 2025<br/>
                    - Save additional $1000 and get a Room Upgrade at Platinum when you Pay In Full
                 </p>
            </div>

            <div className="mb-6 mt-8 bg-gray-200 px-4 md:px-10 py-6">
                <div className="flex flex-col md:flex-row gap-3 items-center justify-between mb-6">
                    <div className="rounded w-full md:flex-1 bg-cover object-cover bg-center bg-no-repeat">
                        <div className="text-3xl mt-3 font-bold text-black text-center">Save $2500</div>
                        <img src={a360reversed} alt="A360" className="w-full max-w-[380px] mx-auto my-4" />
                    </div>
                    <p className="text-black mb-2 text-base md:hidden px-2">
                 - $10,000 Deposit Today is 100% refundable for 30 days<br/>
                 - Balance of $11,000 Due By December 31st, 2024</p>
                    <div className="w-full md:flex-1 m-auto">
                        <ActionButtons actionBtns={[
                            {text:"Pay In Full - $21K", submitting:false, onClick:()=>{window.open('https://buy.stripe.com/dR6eX95WReES2Qw3d7', '_blank')}},
                            {text:"Pay Deposit - $10K", submitting:false, onClick:()=>{window.open('https://buy.stripe.com/5kA16jfxrgN00Io7tm', '_blank')}}] as ActionBtn[] ?? []} />
                    </div>
                </div>
                <p className="text-black mb-6 text-base hidden md:block">
                 - $10,000 Deposit Today is 100% refundable for 30 days<br/>
                 - Balance of $11,000 Due By December 31st, 2024</p>
            </div>

            <WireInstructions open={showWireInstructions} setOpen={setShowWireInstructions} type={wireType}/>
        </div>
    );
};

const ActionButtons: React.FC<{ actionBtns: ActionBtn[] }> = ({ actionBtns }) => {
    return (
        <div className='grid grid-cols-1 auto-rows-fr gap-6'>
            {
                actionBtns?.map((actionBtn, idx) => <button key={idx} onClick={actionBtn.onClick} disabled={actionBtn.submitting} className="btn btn-primary w-full h-full m-auto lg:w-[75%] self-center justify-center text-white min-h-12 font-bold text-base md:text-xl rounded-md py-2 md:py-3 px-4 md:px-6">
                    <PulseLoader size="5px" color="#000000" loading={actionBtn.submitting} /> {!actionBtn.submitting && actionBtn.text}
                </button>)
            }
        </div>
    )
}

function WireInstructions({open, setOpen, type}: {open: boolean, setOpen: (open: boolean) => void, type: 'a360' | 'plat'}) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [agree, setAgree] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    let productId = type === 'a360' ? 62 : 69;
    let productName = type === 'a360' ? '2025 Abundance360 GIP' : '2025 Platinum Longevity Deposit';
    let price = type === 'a360' ? 21000 : 10000;

    const handleSubmit = async () => {
        if (!name || !email || !phone || !agree) {
            alert('All fields are required and you must agree to make the purchase.');
            return;
        }

        setSubmitting(true);

        try {
            await axios.post('https://hook.us1.make.com/3ttcspo4tcgh7qlkhgrbtlnprnoch8q4',         {
                "email":email,
                "display_name":name,
                "company":"A360",
                "qb_product_id":productId,
                "product_name":productName,
                "total_amount":price,
                "unit_price":price,
                "phone":phone
            });
            setSubmitted(true);
        } catch (error) {
            alert('Submission failed');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Dialog open={open} onClose={() => {setSubmitted(false); setOpen(false)}} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-indigo-600" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        SEND INVOICE / WIRE INSTRUCTIONS
                                    </DialogTitle>
                                    <div className="mt-2">
                                        {submitted ? (
                                            <>
                                            <p className="mb-2">Success! You should receive an invoice within a few minutes. <br/>Wire instructions are included.</p>

                                            </>

                                        ) : (
                                            <>
                                                <p className="mb-2">Fill in the form below to receive an Invoice with instructions for wire payment.</p>
                                                <div className="mb-4">
                                                    <label className="block text-base text-gray-700">Name</label>
                                                    <input
                                                        type="text"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        required
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block text-base text-gray-700">Email</label>
                                                    <input
                                                        type="email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block text-base text-gray-700">Phone</label>
                                                    <input
                                                        type="tel"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        required
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="inline-flex items-center">
                                                        <input
                                                            type="checkbox"
                                                            checked={agree}
                                                            onChange={(e) => setAgree(e.target.checked)}
                                                            required
                                                            className="form-checkbox"
                                                        />
                                                        <span className="ml-2">I agree to remit payment by wire or credit card, the deadline for payment is 9/20.</span>
                                                    </label>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="button"
                                onClick={submitted ? () => setOpen(false) : handleSubmit}
                                disabled={submitting}
                                className="inline-flex w-full justify-center rounded-md bg-gold px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-80 sm:ml-3 sm:w-auto"
                            >
                                {submitting ? 'Submitting...' : submitted ? 'Close' : 'Submit'}
                            </button>
                            {!submitted && (
                                <button
                                    type="button"
                                    onClick={() => setOpen(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            )}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default LongevityPlatinum;
